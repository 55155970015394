<template>
  <div class="bind-mch">
    <navbar title="物业认证" />
    <div class="bind-box">
      <van-cell-group>
        <van-field v-model="loginName" label="物业人员账号" placeholder="请输入物业人员账号" />
      </van-cell-group>
      <van-cell-group>
        <van-field type="password" v-model="password" label="物业人员密码" placeholder="请输入物业人员密码" />
      </van-cell-group>
        <div class="bind-btn" @click="submitForm">确定</div>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import sha1 from 'sha1'

export default {
  name: "bindMch",
  data(){
      return{
        loginName:"",
        password:""
      }
  },
  components: {
    navbar,
  },
  methods:{
    submitForm() {
      var page = this;
      if (!this.loginName) {
        this.$toast.fail("请输入物业人员账号");
        return false;
      }
      if (!this.password) {
        this.$toast.fail("请输入物业人员密码");
        return false;
      }
      var params = {
        loginName: sha1(this.loginName),
        password: sha1(this.password),
      };
      this.$post( "user/bindMch", params,{ toast:true,toastName:"处理中……"}).then((res) => {
        if (res.status == 200) {
          var router =
              this.$toast.success({
                message:"绑定成功",
                duration:2000,
                onClose:function(){
                  page.$router.push("/my/index");
                }
              });
        } else if(res.status == 610) {
          this.$toast.fail({
            message:res.msg,
            duration:1500,
            onClose:function(){
              page.$router.push("/my/index");
            }
          });
        }else{
          this.$toast.fail(res.msg);
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
.bind-mch {
    .bind-box{
        padding: 20px;
        /deep/.van-cell-group{
            margin-bottom: 30px;
            .van-cell{
                background: #f6f6f6;
                padding: 8px 0;
                color: #535353;
                font-size: 17px;
                border: unset;
                border-bottom: 1px solid #ddd;
                input{
                    font-size: 16px;
                }
            }
        }
        .bind-btn{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 5px;
            background: #00D3C4;
            font-size: 18px;
            height: 40px;
        }
    }
}
</style>